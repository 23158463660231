<template>
  <div class="hello">
    <br>
    <div class="card z-depth-0">
        <div class="card-content">
          <div class="row">
            <div class="container" v-if="menu===1">
              <div class="row">
               <div class="input-field col s12 m10 offset-m1 l6 offset-l3">
                    <input id="email" type="text" class="validate" v-model="email">
                    <label for="email">E-Mail</label>
                </div>
                </div>
                <div class="row">
                <div class="input-field col s12 m10 offset-m1 l6 offset-l3">
                    <input id="passwort" type="password" class="validate" v-model="pw" v-on:keyup.enter="login_send">
                    <label for="passwort">Passwort</label>
                </div>
                </div>
                <div class="row">
                <div class="col s12 m10 offset-m1 l6 offset-l3 center">
                    <a class="waves-effect waves-light btn btn-charly white grey-text text-darken-4" @click="login_go()">Anmelden</a>
                </div>
                </div>
                <div class="row">
                <div class="col s12 m10 offset-m1 l6 offset-l3 center">
                    <p><a class="waves-effect waves-light" @click="menu = 3">Passwort vergessen?</a></p>
                </div>
                </div>
                <div class="row">
                <div class="col s12 m10 offset-m1 l6 offset-l3 center">
                    <a class="waves-effect waves-light btn btn-charly white grey-text text-darken-4 z-depth-0" @click="menu = 2">Jetzt registrieren</a>
                </div>
                </div>
              </div>
              <div class="container" v-if="menu===2">
                <!-- container start -->
                 <div class="row">
                  <div class="input-field col s12 m10 offset-m1 l6 offset-l3">
                    <input id="vname" type="text" class="validate" v-model="vname">
                    <label for="vname">Vorname</label>
                  </div>
                </div>
                 <div class="row">
                  <div class="input-field col s12 m10 offset-m1 l6 offset-l3">
                    <input id="nname" type="text" class="validate" v-model="nname">
                    <label for="nname">Nachname</label>
                  </div>
                </div>
                 <div class="row">
                  <div class="input-field col s12 m10 offset-m1 l6 offset-l3">
                    <input id="tel" type="text" class="validate" v-model="tel">
                    <label for="tel">Telefonnummer</label>
                  </div>
                </div>
                <div class="row">
                  <div class="input-field col s12 m10 offset-m1 l6 offset-l3">
                    <input id="email" type="text" class="validate" v-model="email">
                    <label for="email">E-Mail</label>
                  </div>
                </div>
                <div class="row">
                  <div class="input-field col s12 m10 offset-m1 l6 offset-l3">
                    <input id="passwort" type="password" class="validate" v-model="pw" v-on:keyup.enter="login_send">
                    <label for="passwort">Passwort</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col s12 m10 offset-m1 l6 offset-l3 center">
                    <a class="waves-effect waves-light btn btn-charly white grey-text text-darken-4 z-depth-0" @click="register_go()">Jetzt registrieren</a>
                  </div>
                </div>
                <div class="row">
                  <div class="col s12 m10 offset-m1 l6 offset-l3 center">
                    <a class="waves-effect waves-light btn-flat white grey-text text-darken-4 z-depth-0" @click="menu = 1"><i class="material-icons left">arrow_back</i>Zurück zum Login</a>
                  </div>
                </div>
                <!-- container ende -->
              </div>
              <div class="container" v-if="menu===3">
                <!-- container start -->
                <div class="row">
                  <div class="input-field col s12 m10 offset-m1 l6 offset-l3">
                    <input id="email" type="text" class="validate" v-model="email">
                    <label for="email">E-Mail</label>
                  </div>
                </div>
                 <div class="row">
                  <div class="col s12 m10 offset-m1 l6 offset-l3 center">
                    <a class="waves-effect waves-light btn btn-charly white grey-text text-darken-4" @click="pw_reset_go()">Neues Passwort anfordern.</a>
                  </div>
                </div>
                <div class="row">
                  <div class="col s12 m10 offset-m1 l6 offset-l3 center">
                    <a class="waves-effect waves-light btn-flat white grey-text text-darken-4 z-depth-0" @click="menu = 1"><i class="material-icons left">arrow_back</i>Zurück zum Login</a>
                  </div>
                </div>
                <!-- container ende -->
              </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'login',
  data: function () {
    return {
      menu: 1,
      vname: '',
      nname: '',
      tel: '',
      email: '',
      pw: ''
    }
  },
  methods: {
    login_go: function () {
      var config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://apps.charlythetrader.de/api/user/user_login.php', {
        cuid: this.$store.state.cuid,
        email: this.email,
        pw: this.pw
      }, config)
        .then(response => {
          console.log(response.data.jwt)
          var cookiename = 'ccookie' + this.$store.state.cuid
          this.$cookies.set(cookiename, response.data.jwt, '3d', '', '', 'Secure', 'None')
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    register_go: function () {
      var config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://apps.charlythetrader.de/api/user/user_register.php', {
        cuid: this.$store.state.cuid,
        vname: this.vname,
        nname: this.nname,
        tel: this.tel,
        email: this.email,
        pw: this.pw
      }, config)
        .then(response => {
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    pw_reset_go: function () {
      var config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://apps.charlythetrader.de/api/user/user_pw_reset.php', {
        cuid: this.$store.state.cuid,
        vname: this.vname,
        nname: this.nname,
        tel: this.tel,
        email: this.email,
        pw: this.pw
      }, config)
        .then(response => {
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
