import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: 0,
    cuid: '',
    EventModal: {
      title: '',
      text: '',
      date: '',
      time: '',
      ort: '',
      teilnehmerzahl: '',
      timestamp: '',
      id: '',
      anmeldungen: 0
    },
    kalender: {
      date: ''
    }
  },
  mutations: {
    event_data (state, data) {
      state.EventModal.title = data.titel
      state.EventModal.text = data.text
      state.EventModal.date = data.date
      state.EventModal.time = data.start_zeit + ' - ' + data.end_zeit
      state.EventModal.ort = data.ort
      state.EventModal.teilnehmerzahl = data.places
      state.EventModal.timestamp = data.timestamp
      state.EventModal.id = data.id
      state.EventModal.anmeldungen = data.anmeldungen
    },
    kalender_date (state, date) {
      state.kalender.date = date
    }
  },
  actions: {
  },
  modules: {
  }
})
