<template>
<div id="event_modal" class="modal modal-add-notiz">
    <div class="modal-content">
        <div class="row">
            <p class="bold title center">{{data.title}}</p>
            <div class="col s6">
                <p class="bold">Datum: {{data.date}}</p>
                <p class="bold">Uhrzeit: {{data.time}} Uhr </p>
                <p v-if="data.ort != ''" class="bold">Ort: {{data.ort}} </p>
                <p v-if="data.teilnehmerzahl != 0" class="bold">max. Teilnehmerzahl: {{data.teilnehmerzahl}}</p>
                <!-- <p class="bold">noch Freie Plätze: <span>{{freie_plaetze}}</span></p> -->
                <!-- <p><a class="waves-effect waves-light btn btn-charly white grey-text text-darken-4" @click="event_anmelden()">Für diesen Kurs anmelden</a></p> -->
            </div>
            <div class="col s6">
              <br>
            </div>
            <div class="col s12">
                 <p class="bold title">Kursbeschreibung:</p>
                 <p class="bold" v-html="data.text"></p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css'
import axios from 'axios'

export default {
  name: 'KalenderSpalte',
  data: function () {
    return {
      notiz: '',
      time: '',
      date_value: '',
      users: '',
      share: []
    }
  },
  components: {
    // vasdf
  },
  props: {
    // asdf
  },
  mounted: function () {
    M.AutoInit()
  },
  computed: {
    data () {
      return this.$store.state.EventModal
    },
    freie_plaetze () {
      return this.data.teilnehmerzahl - this.data.anmeldungen
    }
  },
  methods: {
    event_anmelden: function () {
      var cookiename = 'ccookie' + this.$store.state.cuid
      var config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://apps.charlythetrader.de/api/kalender/event_anmelden.php', {
        jwt: this.$cookies.get(cookiename),
        event_id: this.data.id,
        timestamp: this.data.timestamp
      }, config)
        .then(response => {
          this.proof = 0
          this.$emit('kalender_reload')
          console.log(response.data)
          console.log(this.data.id)
          console.log(this.data.timestamp)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#bar1 {
   width: 20px;
   height: 80px;
   background: #037CA9;
}

.title {
    font-size:140%;
}
.row-container {
  margin:10px;
  padding:10px;
}
.modal-add-notiz{
  max-height:85vh !important;
  top:25px !important;
  width:50%;
}
textarea{
    height:120px !important;
}
.bild_render {
  width:100px !important;
  height:100px !important;
  border-style: dotted;
}
</style>
