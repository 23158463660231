<template>
<div class="col kalender_col vs12 vm1 vl1" :class="mobile"><!-- kalender col start-->
        <ul class="collection kalender_ul z-depth-0">
            <li data-target="notiz_modal" class="modal-trigger collection-item blue1 white-text kalender_li">
              <div class="center">
                  <span class="bold black-text">{{data.datum}}</span>
              </div>
            </li>
            <li class="collection-item kalender_li2" :class="{ 'grey lighten-4': data.day_num == 6, 'grey lighten-3': data.day_num == 0}">
              <div class="row">
               <!-- asdfasdf -->
               <KalenderEvent v-for="(item, index) in data.events"
                                  :key="index"
                                  :data="item"></KalenderEvent>
              </div>
            </li>
        </ul>
</div>
</template>

<script>
import KalenderEvent from '@/views/kalender/components/KalenderEvent.vue'

export default {
  name: 'KalenderSpalte',
  components: {
    KalenderEvent
  },
  data: function () {
    return {
      screenwidth: '',
      mobile: ''
    }
  },
  props: {
    data: [Array, Object]
  },
  mounted: function () {
    this.screenwidth = window.screen.width
    if (this.screenwidth < 600) {
      this.mobile = 'mobile'
    } else {
      this.mobile = ''
    }
  },
  methods: {
    // Asdf
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.kalender_col {
    padding-right:2px !important;
    padding-left:2px !important;
}

.kalender_li{
       padding-left:10px !important;
       padding-right:10px !important;
       margin-left:0px;
       margin-right:0px;
   }

.kalender_li2{
       padding-left:10px !important;
       padding-right:10px !important;
       margin-left:0px;
       margin-right:0px;
       min-height:600px !important;
       background-color:#ffffff;
   }

  .vs12 {
    width:100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .mobile {
    width: 100% !important;
    margin-left: auto;
    left: auto;
    right: auto;
  }
@media only screen and (min-width: 801px) {
  .vm1 {
    width:50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
}

@media only screen and (min-width: 1000px) {
  .vl1 {
    width:14.25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
}

</style>
