<template>
      <div id="user_modal" class="modal">
    <div class="modal-content">
      <UserLogin></UserLogin>
    </div>
  </div>
</template>

<script>
import UserLogin from '@/components/login.vue'

export default {
  name: 'login',
  components: {
    UserLogin
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
