<template>
  <div class="home white">
    <!-- Kalender Nav -->
    <nav>
      <div class="nav-wrapper" :class="color_navbar">
        <a class="brand-logo" :class="color_navbar_text">{{name}}</a>
        <!--
          <ul id="nav-mobile" class="right hide-on-med-and-down">
            <router-link tag="li" to="/login" class="tab"><a class="waves-effect" :class="color_navbar_text">Einloggen</a></router-link>
            <li v-if="this.$store.state.login === 0"><a :class="color_navbar_text" data-target="user_modal" class="modal-trigger">Einloggen</a></li>
            <li v-if="this.$store.state.login === 1"><a :class="color_navbar_text" class="">Angemeldet</a></li>
            <li v-if="this.$store.state.login === 1"><a :class="color_navbar_text" class="" @click="logout()"><i class="material-icons right">exit_to_app</i>Logout</a></li>
          </ul>
          -->
      </div>
    </nav>
    <!-- Kalender Nav Ende -->
   <div class="row zero-bottom"> <!-- row start kalender headline -->
      <div class="col s12 l5 zero-bottom">
        <div class="left">
          <p>{{week.date}} KW {{week.kw}}</p>
        </div>
      </div>
      <div class="col s12 l2zero-bottom center">
        <p>{{screenwidth}}</p>
        <div class="zero-bottom">
          <ul class="pagination">
            <li class="waves-effect"><a class="pointer" @click="remove_week()"><i class="material-icons">chevron_left</i></a></li>
            <li class="active"><a class="pointer black-text bold" @click="today_week()">heute</a></li>
            <li class="waves-effect"><a class="pointer" @click="add_week()"><i class="material-icons">chevron_right</i></a></li>
          </ul>
        </div>
      </div> <!-- col kalender buttons ende -->
    </div><!-- row ende kalender footer -->
    <div class="row"> <!-- kalender collumns start -->
        <KalenderSpalte v-for="(item, index) in items"
                          v-bind:key="index"
                          v-bind:data="item"></KalenderSpalte>
    </div> <!-- kalender collumns ende -->
    <ModalEventShow></ModalEventShow>
    <UserModal></UserModal>
  </div>
</template>

<script>
import axios from 'axios'
import KalenderSpalte from '@/views/kalender/components/KalenderSpalte.vue'
import ModalEventShow from '@/views/kalender/components/Modal-EventShow.vue'
import UserModal from '@/components/user_modal.vue'
import M from 'materialize-css'

export default {
  name: 'Home',
  components: {
    KalenderSpalte,
    ModalEventShow,
    UserModal
  },
  data: function () {
    return {
      name: '',
      items: [],
      cal: 0,
      week: [],
      color_navbar: 'white',
      color_navbar_text: 'white-text',
      cookiename: '',
      screenwidth: ''
    }
  },
  mounted: function () {
    this.screenwidth = window.screen.width
    this.$store.state.cuid = this.$route.params.id
    M.AutoInit()
    this.init_kalender()
    this.load_kalender()
    this.color_init()
    this.user_init()
    this.cookiename = 'ccookie' + this.$route.params.id
    console.log(this.cookiename)
    console.log(this.$cookies.isKey(this.cookiename))
    if (this.$cookies.isKey(this.cookiename)) {

    } else {
      this.$cookies.set(this.cookiename, '0')
    }
  },
  computed: {
    kalender_date () {
      return this.$store.state.kalender.date
    }
  },
  methods: {
    logout: function () {
      this.$cookies.remove(this.cookiename)
      this.$router.go()
    },
    user_init: function () {
      var config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      var cookiename = 'ccookie' + this.$store.state.cuid
      axios.post('https://apps.charlythetrader.de/api/kalender/app_init.php', {
        jwt: this.$cookies.get(cookiename)
      }, config)
        .then(response => {
          if (response.data) {
            this.$store.state.login = 1
          } else {
            this.$store.state.login = 0
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    color_init: function () {
      if (this.color_navbar === '0') {
        this.color_navbar = 'white'
        this.color_navbar_text = 'black-text'
      } else if (this.color_navbar === '1') {
        this.color_navbar = 'red accent-3'
        this.color_navbar_text = 'white-text'
      } else if (this.color_navbar === '2') {
        this.color_navbar = 'ligh-blue accent-4'
        this.color_navbar_text = 'white-text'
      } else if (this.color_navbar === '3') {
        this.color_navbar = 'indigo accent-4'
        this.color_navbar_text = 'white-text'
      } else if (this.color_navbar === '4') {
        this.color_navbar = 'indigo'
        this.color_navbar_text = 'white-text'
      } else if (this.color_navbar === '5') {
        this.color_navbar = 'lime accent-2'
        this.color_navbar_text = 'black-text'
      } else if (this.color_navbar === '6') {
        this.color_navbar = 'yellow accent-4'
        this.color_navbar_text = 'black-text'
      } else if (this.color_navbar === '7') {
        this.color_navbar = 'teal accent-3'
      } else if (this.color_navbar === '8') {
        this.color_navbar = 'green accent-4'
      } else if (this.color_navbar === '9') {
        this.color_navbar = 'grey darken-4'
      }
    },
    init_kalender: function () {
      var config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://apps.charlythetrader.de/api/kalender/kalender_init.php', {
        cuid: this.$store.state.cuid
      }, config)
        .then(response => {
          console.log(response.data)
          this.color_navbar = response.data.navbar
          this.name = response.data.name
          this.color_init()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    kalender_reload: function () {
      this.load_kalender()
    },
    view_change: function () {
      this.$emit('view_change', 5)
    },
    add_week: function () {
      this.cal = this.cal + 1
      this.load_kalender()
    },
    remove_week: function () {
      this.cal = this.cal - 1
      this.load_kalender()
    },
    today_week: function () {
      this.cal = 0
      this.load_kalender()
    },
    load_kalender: function () {
      this.items = []
      var config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://apps.charlythetrader.de/api/kalender/kalender_load.php', {
        cuid: this.$store.state.cuid,
        cal: this.cal
      }, config)
        .then(response => {
          console.log(response.data)
          this.items = response.data.items
          this.week = response.data.week
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
.brand-logo {
  font-size:18px;
  margin-left:10px;
}
.zero-bottom {
    padding-bottom:0px;
    margin-bottom:0px;
}
.active {
    background-color:#ffffff !important;
    border-style:solid;
    border-radius:5px;
    border-width:2px;
    line-height:15px;
}
.color1 {
/* pink: #f68bdb */
  padding-bottom:10px;
}
.bottom0 {
    margin-bottom:0px;
}
</style>
