<template>
  <div class="home white">
    <nav class="white z-depth-0">
    <div class="nav-wrapper">
      <ul id="nav-mobile" class="left hide-on-med-and-down">
        <li><a class="grey-text text-darken-4"><i class="material-icons left">arrow_back</i>Zurück</a></li>
      </ul>
    </div>
    </nav>
    <LoginView></LoginView>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginView from '@/components/login.vue'

export default {
  name: 'Home',
  components: {
    LoginView
  }
}
</script>
