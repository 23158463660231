<template>
<div class="card clip z-depth-1 modal-trigger" data-target="modal1">
        <!-- small Picture view -->
        <div v-if="view==0" class="zip pointer">
            <img src="https://materializecss.com/images/yuna.jpg" class="image">
            <p class="title_font bold">Klassisches Ballett / Fortgeschritten</p>
            <p class="uhr_font">15:00 - 16:00 Uhr</p>
        </div>
        <!-- small Picture view Ende -->
        <!-- large Picture view -->
        <div v-if="view==1" class="zip pointer">
            <img src="https://materializecss.com/images/yuna.jpg" class="image_large">
            <div class="center">
            <p class="uhr_font">15:00 - 16:00 Uhr</p>
            <p class="title_font bold">Klassisches Ballett / Fortgeschritten</p>
            </div>
        </div>
        <!-- large Picture view ende -->
        <!-- NO Picture view -->
        <div v-if="view==2" class="zip pointer">
            <div class="center">
            <p class="uhr_font">15:00 - 16:00 Uhr</p>
            <p class="title_font bold">Klassisches Ballett / Fortgeschritten</p>
            </div>
        </div>
        <!-- NO Picture view ende -->
        <!-- NO Picture view -->
        <div v-if="view==3" class="zip pointer modal-trigger" data-target="event_modal" @click="modal_data()">
            <div class="row">
            <div class="center">
                <div class="col inhalt">
                  <p class="title_font bold black-text">{{data.titel}}</p>
                  <p class="uhr_font bold">{{data.start_zeit}} - {{data.end_zeit}}</p>
                </div>
            </div>
            </div>
        </div>
        <!-- NO Picture view ende -->
</div>
</template>

<script>
import M from 'materialize-css'

export default {
  name: 'KalenderSpalte',
  data: function () {
    return {
      view: 3
    }
  },
  components: {
    // asdf
  },
  props: {
    data: [Array, Object]
  },
  mounted: function () {
    M.AutoInit()
  },
  methods: {
    modal_data: function () {
      this.$store.commit('event_data', this.data)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
    margin-bottom:2px;
}
.inhalt {
    width:100%;
}
.balken {
    width:20%;
}
.balken_grün {
    margin: 0;
    padding: 0;
    height: 10px;
    width: 45%;
    background-color: green;
    float:left;
}
.balken_red {
    margin: 0;
    padding: 0;
    height: 20px;
    width: 45%;
    background-color: red;
    float:right;
}
.padding {
    margin-left:100px !important;
}
.image_large {
    width:100%;
}
.image {
    float:left !important;
    width:55px;
    height: 55px;
    margin-right: 10px;
    margin-top:2px;
}

   .clip{
    margin-left:10px;
    margin-right:10px;
    margin-top:3px;
    margin-bottom:3px;
    border-radius:5px;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:1px;
    padding-top:5px;
   }

   .zip {
     /* border-radius:1px;
     border-style: dashed;
     border-color:#000000;
     border-width:thin;
     */
     margin-top:2px;
     padding-left:2px;
     height:100%;
   }

  .card:hover{
    background-color:#f5f5f5;
  }
   .icon-k{
     margin-left:5px;
     margin-right:5px;
   }

    .uhr_font{
      margin-top:0px;
      font-size:90%;
    }

   .title_font{
     margin-top:0px;
     margin-bottom:0px;
   }

.modal-show-notiz{
  height:60vh;
  max-height:80vh !important;
  top:5% !important;
  width:30%;
  border-radius:10px;
}
</style>
